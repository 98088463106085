export default function Label(props: {
  for: string;
  title: string;
  error: any;
}) {
  return (
    <label
      htmlFor={props.for}
      className="flex flex-row w-fit !items-center !justify-center h-fit"
    >
      {props.title}
      {props.error && (
        <small className="flex flex-row w-fit h-fit text-red text-center">
          {" : "}
          {props.error}
        </small>
      )}
    </label>
  );
}
